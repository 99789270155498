.home-sermon-banner {
  width: 100%;
  max-width: 1100px;
  margin: 5px auto 20px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.banner-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.static-image,
.hover-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.hover-gif {
  opacity: 0;
}

.home-sermon-banner:hover .static-image {
  opacity: 0;
}

.home-sermon-banner:hover .hover-gif {
  opacity: 1;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  background: linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4), transparent);
  color: white;
}

.latest-label {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 500;
  opacity: 0.9;
}

.banner-overlay h2 {
  font-size: 2em;
  margin: 10px 0;
  color: white;
}

.sermon-date {
  position: absolute;
  bottom: 20px;
  right: 30px;
  margin: 0;
  font-size: 0.9em;
  opacity: 0.9;
  color: white;
}

.banner-buttons {
  margin-right: 150px; /* Make space for the date */
}


.sermon-details,
.sermon-date {
  margin: 5px 0;
  font-size: 0.9em;
  opacity: 0.9;
  color: white;
}

.banner-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.watch-button,
.all-sermons-button {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.watch-button {
  background-color: #58213B;
  color: white;
  transition: background-color 0.2s ease;
}

.watch-button:hover {
  background-color: #7a2e52;
}

.all-sermons-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.watch-button:hover,
.all-sermons-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
  .home-sermon-banner {
    aspect-ratio: 4 / 5; /* Change aspect ratio for mobile */
    margin-top: 5px;
  }

  .banner-overlay {
    padding: 20px;
    text-align: center; /* Center text content */
  }

  .banner-overlay h2 {
    font-size: 1.5em;
  }

  .sermon-date {
    display: none; /* Hide date on mobile */
  }

  .sermon-details {
    padding-bottom: 10px;
  }

  .banner-buttons {
    flex-direction: column; /* Stack buttons in a column */
    align-items: center; /* Center align buttons */
    width: 80%; /* Allow buttons to take more width */
    margin: 0 auto; /* Center the buttons container */
  }

  .watch-button,
  .all-sermons-button {
    width: 100%; /* Make buttons take full width of the container */
  }

  .static-image {
    opacity: 1 !important; /* Ensure static image is always visible */
  }

  .hover-gif {
    display: none !important; /* Ensure GIF is not displayed */
  }

  .home-sermon-banner:hover .static-image {
    opacity: 1; /* Prevent transition to blank screen */
  }
} 