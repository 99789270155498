.main-content-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.featured-sermon-new {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lower-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.calendar-events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.event-card {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-card .event-image-container {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image-container {
  transform: scale(1.05);
}

.sidebar {
  width: calc(40% - 10px);
  background-color: white;
  position: relative;
  z-index: 1;
}

.calendar-event,
.connect,
.follow-on-social,
.join-newsletter {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.calendar-event {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .lower-content {
    flex-direction: column;
  }
  
  .calendar-events,
  .sidebar {
    width: 100%;
    padding-top: 0;
  }
}

.home-page,
.home-page .main-content-new,
.home-page .lower-content,
.home-page .sidebar {
  background-color: white !important;
}

.content-box {
  width: 100%;
  max-width: 1200px;
  margin: 10 auto 10px;
  box-sizing: border-box;
}

.home-page {
  position: relative;
  z-index: 1;
}

.follow-on-social {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.follow-on-social h3 {
  font-size: 1.em;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.follow-on-social ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.follow-on-social li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.follow-on-social li:hover {
  background-color: #f0f0f0;
}

.follow-on-social .icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  flex-shrink: 0;
}

.follow-on-social .social-link {
  flex-grow: 1;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  text-align: left;
  margin-right: auto;
}

.follow-on-social .arrow {
  width: 16px;
  height: 16px;
  opacity: 0.5;
  margin-left: auto;
}

.icon-youtube { background: url('../assets/icons/youtube.svg') no-repeat center; background-size: contain; }
.icon-instagram { background: url('../assets/icons/instagram.svg') no-repeat center; background-size: contain; }
.icon-facebook { background: url('../assets/icons/facebook.svg') no-repeat center; background-size: contain; }

.arrow { background: url('../assets/icons/arrow-right.svg') no-repeat center; background-size: contain; }

.connect {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.connect h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.connect ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.connect li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.connect li:hover {
  background-color: #f0f0f0;
}

.connect .icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
}

.connect .link {
  flex-grow: 1;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1em;
  text-align: left;
}

.sidebar-box {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.sidebar-box h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  text-align: center;
}

.sidebar-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-box li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.sidebar-box li:hover {
  background-color: #f0f0f0;
}

.sidebar-box .link {
  flex-grow: 1;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1em;
}

/* Connect-specific icon styles */
.sidebar-box .icon {
  width: 35px;
  height: 35px;
}
.icon-giving { background: url('../assets/icons/giving.svg') no-repeat center; background-size: contain; }
.icon-calendar { background: url('../assets/icons/calendar.svg') no-repeat center; background-size: contain; }
.icon-prayer-request { background: url('../assets/icons/prayer-request.svg') no-repeat center; background-size: contain; }
.icon-contact-us { background: url('../assets/icons/contact-us.svg') no-repeat center; background-size: contain; }

/* Common styles for sidebar boxes */
.sidebar-box {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.sidebar-box h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  text-align: center;
}

/* Newsletter specific styles */
.join-newsletter {
  text-align: center;
}

.join-newsletter button {
  background-color: #58213B;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-newsletter button:hover {
  background-color: #4CAF50; /* Green color for hover */
}

.sermon-card {
  /* Keep original styling for homepage sermon cards */
  flex: 0 0 auto;
  width: calc(33.333% - 20px);
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}
