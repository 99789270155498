.prayer-request-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 100px 20px 20px;
  text-align: center;
}

.prayer-request-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.prayer-request-page label {
  margin-top: 10px;
  text-align: left;
  width: 100%;
  max-width: 400px;
}

.prayer-request-page input,
.prayer-request-page textarea,
.prayer-request-page select {
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.prayer-request-page textarea#request {
  height: 100px;
}

.prayer-request-page button {
  margin-top: 20px;
  padding: 15px;
  background-color: #58213B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  transition: background-color 0.3s ease;
}

.prayer-request-page button:hover {
  background-color: #7a2e52;
}

.prayer-request-page button.submitted {
  background-color: #28a745;
}

.radio-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.prayer-request-page input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
