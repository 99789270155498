.icon-slider {
  display: none;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #FFFFFF;
  border-bottom: 1px solid #e0e0e0;
}

.icon-slider::-webkit-scrollbar {
  display: none;
}

.icon-slider-inner {
  display: inline-flex;
  padding: 10px 0;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  padding: 0 15px;
  flex: 0 0 auto;
}

.icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.icon-name {
  font-size: 12px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .icon-slider {
    display: block;
    box-shadow: 0 11px 10px -2px rgba(0,0,0,0.1);
  }
}
