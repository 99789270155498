.sermons-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Increased top padding */
}

.sermons-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  margin-top: 0; /* Ensure no extra space above the h1 */
}

.search-bar {
  margin-bottom: 30px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.todays-sermon {
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.todays-sermon img,
.todays-sermon iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Scope all sermon-overlay styles to the sermons-page container */
.sermons-page .sermon-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.817), transparent);
  color: white;
}

.sermons-page .sermon-overlay span {
  font-size: 0.9em;
  text-transform: uppercase;
}

.sermons-page .sermon-overlay h2 {
  color: white;
  font-size: 1.8em;
  margin: 10px 0;
}

.sermons-page .sermon-overlay.hidden {
  display: none;
}

.play-button {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure it's above the image */
  cursor: pointer;
}

.play-button::after {
  content: '';
  border-left: 20px solid #000;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.recent-sermons {
  display: flex;
  gap: 30px; /* Increased gap between cards */
  justify-content: center;
  padding: 20px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.sermon-card {
  flex: 0 0 calc(33.333% - 20px); /* Changed from 300px to take up 1/3 of space */
  min-width: 280px; /* Added minimum width */
  max-width: 400px; /* Added maximum width */
  margin: 0 10px;
  box-sizing: border-box;
  aspect-ratio: 16/9;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.sermon-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sermon-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.4), transparent);
  color: white;
}

.sermon-card h3 {
  font-size: 1.2em;
  margin: 0 0 8px 0;
  color: white;
}

.sermon-card p {
  font-size: 0.9em;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

/* Ensure the "Recent Sermons" h2 stays left-aligned */
.sermons-page h2 {
  text-align: left;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .sermon-card {
    flex: 0 0 calc(50% - 20px); /* Take up half the space on medium screens */
    min-width: 260px;
  }
}

@media (max-width: 768px) {
  .sermon-card {
    flex: 0 0 calc(100% - 20px); /* Full width on mobile */
    max-width: none; /* Remove max-width constraint on mobile */
  }
}

.recent-sermons-slider {
  position: relative;
  width: 100%;
  overflow: visible;
  margin: 0 60px;
  width: calc(100% - 120px);
}

.recent-sermons {
  display: flex;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.sermon-card {
  flex: 0 0 300px;
  margin: 0 10px;
  box-sizing: border-box;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 10;
  border-radius: 4px;
}

.slider-arrow.left {
  left: -60px;
}

.slider-arrow.right {
  right: -60px;
}
