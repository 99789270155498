.footer {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  border-top: 1px solid #e5e5e5;
  padding: 2rem 1rem;
  font-family: Arial, sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.footer-section {
  flex: 1;
  min-width: 150px;
  margin-bottom: 1rem;
  text-align: center; /* Center all content in the section */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section h3 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.footer-section li {
  margin-bottom: 0.25rem;
  text-align: center; 
}

.footer-section a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-section a:hover {
  color: #333;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  padding-top: 1rem;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
}

.footer-bottom .copyright {
  flex: 1;
}

.footer-bottom .phone {
  color: #ff0000;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: #333;
}

@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
  }
  
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .footer-bottom .copyright {
    margin-bottom: 1rem;
  }
  
  .social-icons {
    margin-top: 1rem;
  }
}
