body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 4%;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.401);
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.church-name {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 0.9em;
}

.church-name-top {
  font-size: 0.9em;
  font-weight: bold;
  color: #4a0e4e;
}

.church-name-bottom {
  font-size: 0.9em;
  font-weight: bold;
  color: #4a0e4e;
}

.nav-container {
  display: flex;
}

.nav-container ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-container li {
  margin-left: 20px;
}

.nav-container a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.nav-container a.active {
  font-weight: bold;
  color: hsl(322, 75%, 29%);
}

nav ul li a.active {
  color: #007bff; /* or any color you want for the active state */
  font-weight: bold;
}

.main-header nav ul li a {
  color: #333333; /* Change this to your desired color */
  text-decoration: none;
}

.main-header nav ul li a:hover {
  color: #333333; /* Keep this the same as the non-hover state for consistency */
}

/* Target all navigation links, including the home button */
.main-header nav ul li a,
.main-header nav ul li:first-child a,
.main-header nav ul li.active a {
  color: #333333 !important;
  text-decoration: none;
}

/* Ensure hover state is also consistent */
.main-header nav ul li a:hover,
.main-header nav ul li:first-child a:hover,
.main-header nav ul li.active a:hover {
  color: #333333 !important;
}

/* Reset all navigation link colors */
.main-header nav ul li a,
.main-header nav ul li a:hover,
.main-header nav ul li a:active,
.main-header nav ul li a:focus {
    color: #333333 !important;
    text-decoration: none !important;
}

/* Target the home button specifically */
.main-header nav ul li:first-child a,
.main-header nav ul li:first-child a:hover,
.main-header nav ul li:first-child a:active,
.main-header nav ul li:first-child a:focus {
    color: #333333 !important;
}

/* If the above doesn't work, try this more aggressive approach */
.main-header nav ul li a[href="/"],
.main-header nav ul li a[href="/"]:hover,
.main-header nav ul li a[href="/"]:active,
.main-header nav ul li a[href="/"]:focus {
    color: #333333 !important;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.menu-icon span {
  width: 1.7rem;
  height: 0.21rem;
  background: #333;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  transform-origin: 1px;
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px);
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

/* Desktop styles */
@media (min-width: 769px) {
  .header {
    flex-direction: row;
  }

  .logo-section {
    display: flex;
    align-items: center;
    order: 2;
  }

  .menu-icon {
    order: 1;
    margin-right: 15px;
  }

  /* Regular navigation styles */
  .nav-container {
    display: flex;
    order: 3;
    margin-left: auto;
  }

  /* Hide the default nav when menu is open */
  .nav-container.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 9;
    transform: translateX(-100%);
  }

  /* Show and animate the slide-out menu when both open and active */
  .nav-container.open.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 9;
    animation: slideInLeft 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  .nav-container.open.active.closing {
    animation: slideOutLeft 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .nav-container ul {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-container li {
    margin: 0 15px;
  }

  .nav-container.open.active ul {
    flex-direction: column;
    padding-top: 70px;
    width: 100%;
  }

  .nav-container.open.active li {
    margin: 15px 0;
    text-align: left;
    padding-left: 30px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .header {
    padding: 10px 4%;
  }

  .logo-section {
    order: 1;
  }

  .menu-icon {
    order: 3;
    margin-left: auto;
  }

  .nav-container {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .nav-container.open {
    right: 0;
  }

  .nav-container ul {
    flex-direction: column;
    padding-top: 70px;
  }

  .nav-container li {
    margin: 15px 0;
    text-align: left;
    padding-left: 30px;
  }
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.live-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.live-indicator:hover {
  background-color: rgba(255, 0, 0, 0.1);
  transform: scale(1.05);
}

.live-dot {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  animation: flash 2s infinite;
}

.live-text {
  color: #ff0000;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  animation: flash 2s infinite;
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
