.about-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Added top padding to account for header */
}

.about-content {
  margin-top: 40px;
}

h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 30px;
}

section {
  margin-bottom: 40px;
}

h2 {
  font-size: 1.8em;
  color: #444;
  margin-bottom: 15px;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}
