.events-page {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #fff;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.events-container {
  display: flex;
  gap: 20px;
}

.filters-column {
  width: 23%;
  max-width: 200px;
}

.search-bar {
  margin-bottom: 20px;
  width: 90%;
  max-width: 200px;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-bar input:focus {
  outline: none;
  border-color: #58213B;
  box-shadow: 0 0 0 2px rgba(88, 33, 59, 0.2);
}

.filters {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.filter-section {
  margin-bottom: 15px;
}

.filter-section:last-child {
  margin-bottom: 0;
}

.filter-section h3 {
  margin-bottom: 10px;
  font-size: 1em;
}

.filter-section label {
  display: block;
  margin-bottom: 5px;
}

.filter-section label:last-child {
  margin-bottom: 0;
}

.filter-section input[type="checkbox"] {
  margin-right: 8px;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.event-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: #fff;
  /* Keep the outer dimensions the same */
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.event-card:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.event-card img {
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

.event-card-content {
  padding: 15px;
}

.event-card h3 {
  margin: 0 0 10px 0;
  font-size: 1.1em;
  color: #333;
}

.event-card p {
  margin: 0 0 10px 0;
  font-size: 0.9em;
  color: #666;
}

.event-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #888;
}

.event-card-link {
  text-decoration: none;
  color: inherit;
}

.event-card-link:hover {
  text-decoration: none;
}

.filter-toggle {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #58213B;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.search-and-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  flex-grow: 1;
  margin-right: 10px;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.filter-toggle {
  background-color: #58213B;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .events-container {
    flex-direction: column;
  }

  .filters-column {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    transition: left 0.3s ease-in-out;
    overflow-y: auto;
  }

  .filters-column.visible {
    left: 0;
  }

  .filter-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .event-grid {
    width: 100%;
  }

  .search-and-filter {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .filter-toggle {
    align-self: flex-end;
  }

  .filters-column {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .filters-column.visible {
    right: 0;
  }

  .content-wrapper {
    padding: 15px;
  }

  .event-grid {
    grid-template-columns: 1fr;
  }

  .event-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .search-and-filter {
    flex-direction: row;
    align-items: center;
  }

  .search-bar {
    flex-grow: 1;
    margin-right: 10px;
  }

  .filter-toggle {
    flex-shrink: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .filters-column {
    width: 80%;
    max-width: 300px;
  }

  .event-card {
    width: 100%;
  }

  .event-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
