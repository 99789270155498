.event-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all content */
}

.event-image-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Restore max-width */
  padding-top: 40%;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 30px;
}

/* Update the full-height style to match other images */
.event-image-container.full-height {
  padding-top: 40%;
  height: auto;
  max-width: 800px; /* Match regular image container */
  margin: 0 0 30px 0;
  background-color: transparent;
}

.event-image-container.full-height .event-image {
  position: absolute; /* Make it match other images */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Use cover to maintain aspect ratio */
}

.event-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7), transparent);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.event-title {
  color: white;
  margin: 0;
  font-size: 18px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.event-info-grid {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px; /* Match image width */
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.info-box {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.date-time-box {
  flex-direction: column;
  padding: 0;
}

.date-section, .time-section {
  display: flex;
  align-items: center;
  padding: 15px;
}

.date-section {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}

.info-icon {
  font-size: 24px;
  margin-right: 15px;
  width: 24px;
  text-align: center;
  flex-shrink: 0;
}

.info-content {
  flex-grow: 1;
}

.info-content h3 {
  margin: 0 0 12px 0;
  font-size: 18px;
  font-weight: bold;
}

.info-content p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.pricing-header {
  font-weight: bold;
  text-decoration: underline;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.about-box {
  flex-grow: 1;
  height: 100%;
}

.donation-section {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.donate-button {
  display: inline-block;
  background-color: #58213B;
  color: white;
  padding: 12px 25px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s ease;
  min-width: 150px;
}

.donate-button:hover {
  background-color: #6f2a4a;
}

.donation-note {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
  font-style: italic;
  text-align: center;
}

@media (max-width: 600px) {
  .event-details-container {
    padding: 100px 20px 20px 20px; /* Smaller padding on mobile */
  }
  
  .event-info-grid {
    flex-direction: column;
    margin-bottom: 20px;
    gap: 20px; /* Smaller gap on mobile */
  }
}

/* Style for the meeting details */
.meeting-details {
  margin-top: 15px;
  font-family: monospace;
  font-size: 13px;
}

.meeting-details p {
  margin: 5px 0;
}

.one-tap-mobile {
  margin-top: 15px;
}

.one-tap-mobile p {
  margin: 3px 0;
  word-break: break-all;
}
