body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.upcoming-event,
.featured-sermon {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}

.two-column-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.calendar-events {
  width: 30%;
}

.sidebar {
  width: 65%;
}

.calendar-event,
.connect,
.follow-on-social,
.join-newsletter {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .two-column-section {
    flex-direction: column;
  }
  
  .calendar-events,
  .sidebar {
    width: 100%;
  }
}

.main-content-new {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .main-content-new {
    padding: 15px;
  }

  .content-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .sermon-card {
    margin-left: 0;
    margin-right: 0;
  }

  .lower-content {
    padding: 0;
  }

  .sidebar-box.connect,
  .sidebar-box.follow-on-social {
    display: none;
  }
}

.sermon-card {
  /* Keep original styling for homepage sermon cards */
  flex: 0 0 auto;
  width: calc(33.333% - 20px);
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

/* ... rest of the original sermon card styles ... */

.social-box {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
}

.social-box h3 {
  margin: 0 0 15px 0;
  font-size: 1.2em;
  color: #333;
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.social-links a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  text-decoration: none;
  font-size: 1em;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.social-links a:hover {
  background-color: #f5f5f5;
}

.social-links svg {
  font-size: 1.2em;
}

.social-box img {
  border-radius: 4px;
}

.follow-on-social h3 {
  text-align: center;
  margin-bottom: 20px;
}

.social-box.galaphotos {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  overflow: hidden;
}

.social-box.galaphotos img {
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.social-box.galaphotos:hover img {
  transform: scale(1.05);
}
