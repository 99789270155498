.tithes-offering-page {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.left-section {
  flex: 1;
  background-size: cover;
  background-position: center;
  position: relative;
  filter: brightness(1.9);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  text-align: center;
}

.overlay h1 {
  color: white !important;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.overlay h2 {
  color: white !important;
  font-size: 1.5rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.right-section {
  flex: 1;
  padding: 3rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  box-shadow: -4px 0 20px rgba(0, 0, 0, 0.1);
}

.right-section > * {
  max-width: 400px;
  width: 100%;
}

h1, h2, h3, h4 {
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.giving-options,
.payment-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
  margin-bottom: 2rem;
}

.giving-options button,
.payment-options button {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid #e0e0e0;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  color: #333;
  font-weight: 500;
}

.giving-options button:hover,
.payment-options button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #58213B;
}

button.active {
  background-color: #58213B;
  color: white;
  border-color: #58213B;
  box-shadow: 0 4px 8px rgba(88, 33, 59, 0.2);
}

.campus-select input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.campus-select input:focus {
  outline: none;
  border-color: #58213B;
  box-shadow: 0 0 0 2px rgba(88, 33, 59, 0.1);
}

.additional-links {
  width: 100%;
  max-width: 400px;
  margin: 2rem auto 0;
  text-align: center;
}

.additional-links a {
  display: inline-block;
  width: auto;
  color: #58213B;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.additional-links a:hover {
  color: #6f2a4a;
}

.script-logo {
  position: absolute;
  bottom: 30px;
  right: 50px;
  width: 120px;
  height: auto;
  opacity: 0.9;
  transition: opacity 0.2s ease;
}

.script-logo:hover {
  opacity: 1;
}

.faq-link {
  display: inline-block;
  color: #58213B;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.faq-link:hover {
  background-color: rgba(88, 33, 59, 0.1);
}

@media (max-width: 768px) {
  .tithes-offering-page {
    flex-direction: column;
    height: auto;
  }

  .left-section {
    height: 35vh;
    min-height: 300px;
  }

  .right-section {
    padding: 2rem 1.5rem;
  }

  .overlay h1 {
    font-size: 2rem;
  }

  .overlay h2 {
    font-size: 1.2rem;
  }

  .script-logo {
    bottom: 20px;
    right: 20px;
    width: 80px;
  }

  .giving-options button,
  .payment-options button {
    padding: 0.8rem 1.2rem;
  }
}

.payment-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.payment-options button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
}

.additional-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
